@media (min-width: 768px) {
    .home-page-tiles-group-1{
        flex-grow: 2;
    }
    
    .home-page-tiles-group-2{
        flex-grow: 1;
        
    }

    .home-page-tiles-stats{
        flex-grow: 4;
    }
    
    .home-page-tiles-devices-pdf{
        flex-grow: 2;
    }

}

@media screen and(min-width:768px) and(min-height:768px){

    .home-page-tiles-container{
        height:100% !important
    }
}

@media screen and(min-width:768px) and(max-height:768px){

    .home-page-tiles-container{
        height: initial !important;
        aspect-ratio: 1/1 !important;
    }
}
@media (max-width: 768px) {
    .home-page-tiles-container{
        // height:200%
        height: initial !important;
        aspect-ratio: 1/2.7 !important;
    }

    .home-page-tiles-group-1{
        flex-grow: 1;
    }
    
    .home-page-tiles-group-2{
        flex-grow: 1;
        
    }
    .home-page-tiles-stats{
        flex-grow: 2;
    }
    .home-page-tiles-devices-pdf{
        flex-grow: 1;
    }
    
}


