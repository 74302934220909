.alarm-items-container {
    overflow-y: visible;
  }
  @media (min-width: 768px) {
    .alarm-items-container {
      overflow-y: scroll;
    }
  }

  .input-number-no-arrows{
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button{
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
  }