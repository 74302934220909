@media (min-width:992px){
    .login-container{
        width:100%;
        height: 100%;
    }
    .login-container-image{
        width: 50%;
        height: 100%;
    }
    .login-container-form{
        width: 50%;
        height: 100%;
    }
}

@media (max-width:991px){
    .login-container{
        width: 100%;
    }
    .login-container-image{
        width: 100%;
        aspect-ratio: 4/3;
    }
    .login-container-form{
        width: 100%;
    }

}