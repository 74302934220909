// $light:#f8f9fa;

// $dark: #060a0e;
// $light: #e8e9eb;


$light: #e8ebe9;
$dark: #050d08;
$primary: #1EB361;

@import "~bootswatch/dist/cosmo/variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/cosmo/bootswatch";

#main-container *::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}
#main-container * ::-webkit-scrollbar-track {
  background: transparent;
}
#main-container * ::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.5);
  border-radius: 20px;
  border: transparent;
}
#main-container * {
  scrollbar-width: thin;
}

body{
  background-color: $light;
}
